import React, { useCallback, useContext, useMemo, useState } from "react";

import { SUPER_ADMIN } from "../../constants/roles";
import { useCloseOnAction } from "../../hooks/useCloseOnAction";
import AuthService from "../../services/auth-service";
import { FormContext } from "../form/formContext";
import { PopupsContext } from "./popupsContext";

export const PopupsState = ({ children }) => {
  const { user, updateStatus } = AuthService;
  const { setErrors } = useContext(FormContext);
  const [popups, setPopup] = useState({
    hintHello: user && user.role !== SUPER_ADMIN && user.isNew
  });

  const scrollToError = useCallback(() => {
    const popupBody = document.querySelector(".popup-body");
    const errorLabel = document.querySelector(".label-input_error");
    const errorPosition =
      errorLabel && errorLabel.offsetTop - errorLabel.offsetHeight;

    popupBody &&
      errorPosition &&
      popupBody.scroll({
        top: errorPosition,
        left: 0,
        behavior: "smooth"
      });
  }, []);

  const toggleOpenPopup = useCallback((popups) => {
    const popupsKeysArr = Object.keys(popups);
    const isHint = popupsKeysArr.some((popup) => popup.includes("hint"));

    if (popupsKeysArr.some((popup) => popups[popup]) && !isHint) {
      document.querySelector("body").style.overflow = "hidden";
    }
    if (popupsKeysArr.every((popup) => !popups[popup])) {
      document.querySelector("body").style.overflow = "auto";
    }

    setErrors({});
    setPopup(popups);
  }, []);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        toggleOpenPopup({});
      }
    },
    [toggleOpenPopup]
  );

  useCloseOnAction("keydown", escFunction);

  const disableHints = useCallback(() => {
    updateStatus();
    toggleOpenPopup({});
  }, []);

  const getHintClass = useCallback(() => {
    const hint = Object.keys(popups).find(
      (k) => k.includes("hint") && popups[k]
    );

    return hint ? hint.replace("hint", "hint-").toLowerCase() : "";
  }, [popups]);

  const value = useMemo(
    () => ({
      scrollToError,
      toggleOpenPopup,
      popups,
      disableHints,
      getHintClass
    }),
    [popups]
  );

  return (
    <PopupsContext.Provider value={value}>{children}</PopupsContext.Provider>
  );
};

import { GET_TESTS, UPDATE_TEST } from "../../types/actionTypes";
import { Test } from "../../types/types";

const initialState = {};

export default function tests(state: any = initialState, action: any) {
  switch (action.type) {
    case GET_TESTS:
      return action.data;
    case UPDATE_TEST:
      return {
        ...state,
        items: state.items.map((test: Test) => {
          if (action.test.id === test.id) {
            return action.test;
          }

          return test;
        })
      };
    // case DELETE_TESTS:
    //   return {
    //     ...state,
    //     items: state.items.filter(
    //       (test: Test) =>
    //         !action.ids.some((id: string) => Number(id) === test.id)
    //     ),
    //   };
    default:
      return state;
  }
}

import axios from "axios";

import { API_URL } from "../constants/api";
import { Platform } from "../types/types";

export const UPDATE_NODE = (id: number, node: Node) => {
  return axios.put(`${API_URL}/nodes/${id}`, node);
};

export const CREATE_NODE = (
  platform: Platform,
  node: Node,
  planId?: number
) => {
  return axios.post(
    `${API_URL}/nodes/platform=${platform}`,
    node,
    planId
      ? {
          params: {
            platform,
            entity: "test-plans",
            testplan: planId
          }
        }
      : { params: { platform, entity: "settings" } }
  );
};

export const DELETE_NODE = (id: number) => {
  return axios.delete(`${API_URL}/nodes/${id}`);
};

export const GET_NODE = (id: number) => {
  return axios.get(`${API_URL}/nodes/${id}`);
};

export const GET_NODES = (platform: Platform) => {
  return axios.get(`${API_URL}/nodes/platform=${platform}`);
};

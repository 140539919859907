import axios from "axios";

import { API_URL } from "../constants/api";
import { Platform, Test } from "../types/types";

export const GET_PLANS = (
  platform: Platform = "IOS",
  params: any,
  id: string
) =>
  axios.get(`${API_URL}/test-plans/platform=${platform}${id ? `/${id}` : ""}`, {
    params
  });

export const CREATE_PLAN = (
  platform: Platform = "IOS",
  data: { tests: Array<Test>; name: string }
) => axios.post(`${API_URL}/test-plans/platform=${platform}`, data);

export const MOVE_TESTS_TO_PLAN = (
  id: number,
  data: { tests: Array<string> }
) => {
  return axios.post(`${API_URL}/test-plans/${id}`, {
    ...data
  });
};

export const DELETE_PLAN = (id: number) =>
  axios.delete(`${API_URL}/test-plans/${id}`);

export const UPDATE_PLAN_SETTINGS = (
  platform: Platform = "IOS",
  data: any,
  id: number
) => {
  return axios.put(
    `${API_URL}/test-plans/platform=${platform}/${id}/settings`,
    data
  );
};

export const GET_PLAN_REPORT = (platform: Platform = "IOS", id: string) =>
  axios.get(`${API_URL}/test-plans/platform=${platform}/${id}/report`);

export const GET_PLAN_SETTINGS = (platform: Platform = "IOS", id: string) =>
  axios.get(`${API_URL}/test-plans/platform=${platform}/${id}/settings`);

export const GET_PLAN_CHART = (platform: Platform = "IOS", id: string) =>
  axios.get(`${API_URL}/test-plans/platform=${platform}/${id}/chart`);

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { FormState } from "./context/form/formState";
import { PopupsState } from "./context/popup/popupsState";
import { GlobalState } from "./store";

ReactDOM.render(
  <GlobalState>
    <BrowserRouter>
      <FormState>
        <PopupsState>
          <App />
        </PopupsState>
      </FormState>
    </BrowserRouter>
  </GlobalState>,
  document.getElementById("root")
);

import React, { useMemo, useState } from "react";

import { GroupsContext } from "./groupsContext";

export const GroupsState = ({ children }) => {
  const [groups, toggleGroup] = useState({});
  const [groupId, selectGroup] = useState(0);

  const value = useMemo(
    () => ({
      selectGroup: (id) => selectGroup(Number(id)),
      toggleGroup,
      groups,
      groupId
    }),
    [groups, groupId]
  );

  return (
    <GroupsContext.Provider value={value}>{children}</GroupsContext.Provider>
  );
};

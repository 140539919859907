import { GET_NODES, REMOVE_NODE } from "../../types/actionTypes";
import { nodeStatus } from "../../types/types";

export default function nodes(state: Array<nodeStatus> = [], action: any) {
  switch (action.type) {
    case GET_NODES:
      return action.data;
    case REMOVE_NODE:
      return state.filter((node: nodeStatus) => node.id !== action.id);
    default:
      return state;
  }
}

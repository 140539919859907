import React, { useCallback, useContext, useMemo, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { inviteSchema } from "../../constants/schemas";
import {
  ACTIVATE_USER,
  DELETE_INVITES,
  GET_INVITES,
  GET_USER_BY_KEY
} from "../../services/users-service";
import * as TYPES from "../../types/actionTypes";
import { FormContext } from "../form/formContext";
import { ParamsContext } from "../params/paramsContext";
import { InvitesContext } from "./invitesContext";
import invites from "./invitesReducer";

export const InvitesState = ({ children }) => {
  const [state, dispatch] = useReducer(invites);
  const { validateFields, changeFormFields } = useContext(FormContext);
  const { params } = useContext(ParamsContext);
  const history = useHistory();

  const getInvites = useCallback(async () => {
    try {
      const { data } = await GET_INVITES({ ...params, status: null });

      if (data) {
        dispatch({
          type: TYPES.GET_INVITES,
          data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [params]);

  const getUserByKey = useCallback(async (key) => {
    try {
      const { data } = await GET_USER_BY_KEY(key);
      changeFormFields(data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);

  const activateUser = useCallback(
    async (key) => {
      try {
        const validData = await validateFields(inviteSchema);
        if (validData) {
          const { data } = await ACTIVATE_USER(key, validData);
          if (data) {
            history.push("/");
          }
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [validateFields]
  );

  const deleteInvites = useCallback(async (ids) => {
    try {
      await DELETE_INVITES(ids);

      getInvites();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);

  const value = useMemo(
    () => ({
      invites: state?.items,
      meta: state?.meta,
      getInvites,
      getUserByKey,
      activateUser,
      deleteInvites
    }),
    [state, params, validateFields]
  );

  return (
    <InvitesContext.Provider value={value}>{children}</InvitesContext.Provider>
  );
};

import {
  GET_SETTINGS,
  REMOVE_NODE,
  UPDATE_NODE,
  UPDATE_SETTINGS,
  UPDATE_TOKEN
} from "../../types/actionTypes";
import { Node } from "../../types/types";
const initialState = {
  nodes: []
};

export default function settings(state = initialState, action: any) {
  switch (action.type) {
    case GET_SETTINGS:
      return action.data;
    case UPDATE_TOKEN:
      return { ...state, token: action.token };
    case UPDATE_NODE:
      return {
        ...state,
        nodes: state.nodes.map((node: Node) => {
          if (node.id === action.node.id) {
            return action.node;
          }
          return node;
        })
      };
    case REMOVE_NODE:
      return {
        ...state,
        nodes: state.nodes.filter((node: Node) => node.id !== action.id)
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}

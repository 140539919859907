export const GET_USERS = "SUCCESS_GET_USERS";
export const GET_TESTS = "GET_TESTS";
export const GET_PLANS = "GET_PLANS";
export const GET_PLAN = "GET_PLAN";
export const DELETE_TESTS_FROM_PLAN = "DELETE_TESTS_FROM_PLAN";
export const GET_INVITES = "GET_INVITES";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_TEST = "UPDATE_TEST";
export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_NODE = "UPDATE_NODE";
export const REMOVE_NODE = "REMOVE_NODE";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const DELETE_TESTS_IN_PLANS = "DELETE_TESTS_IN_PLANS";
export const UPDATE_TEST_IN_PLAN = "UPDATE_TEST_IN_PLAN";
export const GET_NODE = "GET_NODE";
export const GET_NODES = "GET_NODES";
export const GET_PLAN_CHART = "GET_PLAN_CHART";

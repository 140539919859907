import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState
} from "react";
import { useHistory } from "react-router-dom";

import { planSchema, settingsSchema } from "../../constants/schemas";
import { testInfoSchema } from "../../constants/schemas";
import { GroupsContext } from "../../context/groups/groupsContext";
import { useLocationHook } from "../../hooks/useLocation";
import {
  CREATE_PLAN,
  DELETE_PLAN,
  GET_PLAN_CHART,
  GET_PLAN_REPORT,
  GET_PLAN_SETTINGS,
  GET_PLANS,
  MOVE_TESTS_TO_PLAN,
  UPDATE_PLAN_SETTINGS
} from "../../services/plan-service";
import { DELETE_TESTS, UPDATE_TEST } from "../../services/tests-service";
import * as TYPES from "../../types/actionTypes";
import { FormContext } from "../form/formContext";
import { ParamsContext } from "../params/paramsContext";
import { PlatformContext } from "../platform/platformContext";
import { PopupsContext } from "../popup/popupsContext";
import { SettingsContext } from "../settings/settingsContext";
import { PlansContext } from "./plansContext";
import plans from "./plansReducer";

export const PlansState = ({ children }) => {
  const [state, dispatch] = useReducer(plans);
  const [reportData, setReportData] = useState(null);
  const { validateFields } = useContext(FormContext);
  const { currentPlatform } = useContext(PlatformContext);
  const { toggleOpenPopup } = useContext(PopupsContext);
  const { groupId, selectGroup } = useContext(GroupsContext);
  const { params, changeEntity } = useContext(ParamsContext);
  const { setSettings, settings } = useContext(SettingsContext);
  const history = useHistory();
  const { currentUrl } = useLocationHook();

  const createPlan = async () => {
    const existPlans = state.testPlans?.map((p) => p.name);
    try {
      const data = await validateFields(planSchema(existPlans));
      if (data) {
        const response = await CREATE_PLAN(currentPlatform, {
          tests: [],
          name: data.name
        });

        if (response.data) {
          toggleOpenPopup({});
          getPlans();
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getPlans = useCallback(async () => {
    try {
      const { data } = await GET_PLANS(currentPlatform, {
        limit: 50
      });

      if (data) {
        dispatch({
          type: TYPES.GET_PLANS,
          data
        });
        data.testPlans[0] && getPlanChart(data.testPlans[0].id);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [currentPlatform]);

  const searchPlans = async (reset, search) => {
    try {
      const { data } = await GET_PLANS(currentPlatform, {
        ...params,
        status: null,
        page: reset ? 1 : params.page,
        search,
        name: null,
        limit: 50
      });
      if (data) {
        dispatch({
          type: TYPES.GET_PLANS,
          data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getPlan = async (id) => {
    try {
      const { data } = await GET_PLANS(
        currentPlatform,
        { ...params, status: null },
        id
      );
      if (data) {
        dispatch({
          type: TYPES.GET_PLAN,
          plan: data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const addToPlan = async (getSelectedItemsId, clearSelectedRows) => {
    try {
      await MOVE_TESTS_TO_PLAN(groupId, {
        tests: getSelectedItemsId
          .map((id) => id.split("test")[1])
          .filter((item) => item !== undefined)
      });
      selectGroup();
      clearSelectedRows();
      changeEntity("tests");
      toggleOpenPopup({});
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const deleteTestPlan = async (id) => {
    try {
      await DELETE_PLAN(id);
      toggleOpenPopup({});
      history.goBack();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateTestInPlan = async (popupName = null) => {
    try {
      const data = await validateFields(testInfoSchema);

      if (data) {
        const {
          data: { test }
        } = await UPDATE_TEST({
          ...data,
          jiraLink: data.jiraLink || null,
          testRailLink: data.testRailLink || null
        });

        dispatch({
          type: TYPES.UPDATE_TEST_IN_PLAN,
          test
        });

        if (popupName) {
          toggleOpenPopup({ popupName: false });
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const removeTestsFromTestsPlan = async (ids) => {
    try {
      await DELETE_TESTS(state.id, ids);

      dispatch({
        type: TYPES.DELETE_TESTS_FROM_PLAN,
        ids
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getReportData = async () => {
    try {
      const { data } = await GET_PLAN_REPORT(currentPlatform, state.id);
      setReportData(data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getPlanSettings = async (id) => {
    try {
      const { data } = await GET_PLAN_SETTINGS(currentPlatform, id);

      if (data) {
        setSettings({
          type: TYPES.GET_SETTINGS,
          data
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updatePlanSettings = async () => {
    try {
      const updatedSettings = await validateFields(
        settingsSchema(settings.type)
      );

      if (updatedSettings) {
        const planId = currentUrl.replace(/\D+/g, "");
        const { data } = await UPDATE_PLAN_SETTINGS(
          currentPlatform,
          updatedSettings,
          planId
        );

        setSettings({
          type: TYPES.UPDATE_SETTINGS,
          data
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPlanChart = async (id) => {
    try {
      const { data } = await GET_PLAN_CHART(currentPlatform, id);

      if (data) {
        dispatch({
          type: TYPES.GET_PLAN_CHART,
          data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const value = useMemo(
    () => ({
      createPlan,
      searchPlans,
      getPlans,
      addToPlan,
      deleteTestPlan,
      updateTestInPlan,
      removeTestsFromTestsPlan,
      testPlans: state?.testPlans,
      dispatch,
      getPlan,
      plan: state,
      currentPlanId: state?.id,
      reportData,
      getReportData,
      getPlanSettings,
      updatePlanSettings,
      getPlanChart
    }),
    [state, reportData, currentPlatform, params, validateFields, groupId]
  );

  return (
    <PlansContext.Provider value={value}>{children}</PlansContext.Provider>
  );
};

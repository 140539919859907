import "./Popup.scss";

import { useContext } from "react";

import { PopupsContext } from "../../context/popup/popupsContext";
import { Button } from "../Button/Button";

type popupProps = {
  header: string;
  body: JSX.Element;
  toolTip?: boolean;
  gotIt?: boolean;
  button: {
    text: string;
    size?: "big" | "small";
    icon?: string;
    color?: "red" | "blue";
    onClick: (e: unknown) => void;
    disabled?: boolean;
    hide?: boolean;
  };
  name: string;
  isBig?: boolean;
  position?: {
    top: number;
    left: number;
  };
  sticky?: boolean;
  cancelButton?: VoidFunction;
};

export const Popup = (props: popupProps) => {
  const { toggleOpenPopup, popups, disableHints, scrollToError } = useContext(
    PopupsContext
  );
  return popups[props.name] ? (
    <div
      // onMouseDownCapture={(e: React.MouseEvent) => {
      //   const target = e.target as HTMLElement;
      //   if (target.classList.contains("popup") && !form?.changed) {
      //     toggleOpenPopup({ [props.name]: false });
      //   }
      // }}
      className={`popup ${props.name}`}>
      <div
        className={`popup-wrapper ${props.isBig ? "popup-wrapper_big" : ""} ${
          props.toolTip ? "popup-wrapper_tooltips" : ""
        }`}
        style={{
          top: props.position?.top,
          left: props.position?.left
        }}>
        <div className={`popup-wrapper-header_sticky`}>
          <h3>{props.header}</h3>
          <div className="popup-wrapper-buttons">
            <i
              className="icon-close"
              onMouseDown={() => toggleOpenPopup({ [props.name]: false })}
            />
          </div>
        </div>
        <div className="popup-body">{props.body}</div>
        {props.toolTip ? (
          <div className="btn-tooltip">
            {props.gotIt ? (
              <span
                className="btn-tooltip__item btn-tooltip__item_active"
                onClick={disableHints}>
                Got It
              </span>
            ) : (
              <div>
                <span
                  className="btn-tooltip__item btn-tooltip__item_active"
                  onClick={props.button.onClick}>
                  {props.button.text}
                </span>
                <span className="btn-tooltip__item" onClick={disableHints}>
                  Skip Hints
                </span>
              </div>
            )}
          </div>
        ) : (
          <>
            {!props.button.hide && (
              <div className={"popup-bottom_sticky"}>
                <Button
                  colorBtn={props.button.color && "button_red"}
                  {...props.button}
                  onClick={() => {
                    props.button.onClick(props.name);
                    scrollToError();
                  }}
                  size={
                    props.button.size === "big" ? "button_big" : "button_middle"
                  }
                />
                {props.cancelButton && (
                  <Button
                    text="Cancel"
                    onClick={props.cancelButton}
                    size="button_big"
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  ) : null;
};

import AuthService from "../services/auth-service";

export const getAllowedRoutes = (
  routes: Array<{
    component: React.Component;
    display: true;
    iconClass: string;
    name: string;
    path: string;
    permissions: Array<number>;
    title: string;
  }>
) => {
  const { user } = AuthService;

  const role = user?.role;

  return routes.filter(
    ({ permissions }: { permissions: Array<number> }) =>
      role !== undefined && permissions.includes(role)
  );
};

import { platformsRegExp } from "./platforms";
const positiveId = ":id(\\d+)";

export const HOME = "/";
export const USERS = "/users";
export const INVITE = "/invite";
export const INVITES = "/invites";
export const ORGANIZATIONS = "/organizations";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const CREATE_NODE = `/${platformsRegExp}/node/new`;
export const TESTS = `/${platformsRegExp}/tests`;
export const PLANS = `/${platformsRegExp}/test-plans`;
export const TEST_PLAN = `/${platformsRegExp}/test-plan/${positiveId}`;
export const SETTINGS = `/${platformsRegExp}/settings`;
export const PLAN_SETTINGS = `/${platformsRegExp}/test-plan/${positiveId}/settings`;
export const NODE = `/${platformsRegExp}/node/${positiveId}`;
export const NODES = `/${platformsRegExp}/nodes`;

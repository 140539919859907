import React, { useCallback, useMemo, useState } from "react";

import { useLocationHook } from "../../hooks/useLocation";
import { ParamsContext } from "./paramsContext";

export const ParamsState = ({ children }) => {
  const filterParams = {
    status: {
      enabled: false,
      disabled: false,
      quarantined: false
    },
    testSuite: []
  };
  const tableParams = {
    page: 1,
    limit: 30
  };

  const [params, changeParams] = useState({
    ...tableParams,
    ...filterParams
  });
  const { currentUrl } = useLocationHook();

  const resetFilterParams = useCallback(() => {
    changeParams((params) => ({
      ...filterParams,
      ...tableParams,
      entity: params.entity
    }));
  }, []);

  const resetStatusesParams = useCallback((isReset = true) => {
    changeParams((params) => {
      const statuses = { ...params.status };
      for (const key in statuses) {
        if (Object.hasOwnProperty.call(statuses, key)) {
          statuses[key] = isReset ? false : true;
        }
      }
      return {
        ...params,
        status: statuses,
        ...tableParams,
        entity: params.entity
      };
    });
  }, []);

  const resetSuitesParams = useCallback((suites) => {
    changeParams((params) => {
      return {
        ...params,
        testSuite: suites
          ? [
              ...new Set([
                ...params.testSuite,
                ...suites.map(({ id }) => id.toString())
              ])
            ]
          : [],
        ...tableParams,
        entity: params.entity
      };
    });
  }, []);

  const changeTestSuites = useCallback((testSuiteId) => {
    const suiteId = testSuiteId.toString();
    changeParams((params) => ({
      ...params,
      testSuite: params.testSuite.includes(suiteId)
        ? params.testSuite.filter((id) => id !== suiteId)
        : [...params.testSuite, suiteId]
    }));
  }, []);

  const changeStatus = useCallback((key, checked) => {
    changeParams((params) => ({
      ...params,
      status: {
        ...params.status,
        [key]: checked
      }
    }));
  }, []);

  const changeEntity = useCallback(
    (entity) => {
      changeParams({
        ...tableParams,
        ...filterParams,
        entity
      });
    },
    [tableParams, filterParams]
  );

  const changeLimit = useCallback((limit) => {
    changeParams((params) => ({
      ...params,
      limit
    }));
  }, []);

  useMemo(() => {
    const currentPath = currentUrl.replace("/", "").split("/");
    const filteredPathArray = currentPath.filter((p) => !Number(p));
    const currentEntity = filteredPathArray[filteredPathArray.length - 1];
    if (currentPath.length && params.entity !== currentEntity) {
      changeEntity(currentEntity);
    }
  }, [currentUrl]);

  const value = useMemo(
    () => ({
      params,
      changeParams,
      resetFilterParams,
      changeTestSuites,
      changeEntity,
      changeStatus,
      changeLimit,
      resetStatusesParams,
      resetSuitesParams
    }),
    [params]
  );

  return (
    <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>
  );
};

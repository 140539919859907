import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body, .loader:before, .loader:after, input:checked + .slider {
    background-color: ${({ theme }) => theme.body};
  }
  button, .container-checkbox input:checked ~ .checkmark, .plans-page .plans-container_top .action-open, input:checked + .slider:before, .slick-dots .slick-active .dot, .slick-dots .dot:hover, input:checked ~ .radio-button:after {
    background-color: ${({ theme }) => theme.mainColor};
  }
  .container-checkbox input:checked ~ .checkmark, .radio-button_container:hover input ~ .radio-button, .radio-button, input:checked ~ .radio-button {
    border: 1px solid ${({ theme }) => theme.mainColor}
  }
  .loader, .tabs-item {
    color: ${({ theme }) => theme.svgColor}
  }
  .tabs-item:hover svg path, .tabs-item_active svg path {
    fill:${({ theme }) => theme.mainColor}
  }
  .tabs-item svg path {
    fill: ${({ theme }) => theme.svgColor}
  }
  .tabs-item_active, .tabs-item:hover {
    background-color: ${({ theme }) => theme.mainColor} !important;
    color: #fff;
  }
  .tabs-item_disabled, .tabs-item:hover.tabs-item_disabled {
    background-color: ${({ theme }) => theme.middle} !important;
    color: #fff;
    cursor: default;
  }
  button:hover{
    background:${({ theme }) => theme.buttonHover}
  }
  .tbody .icon-setup, .option > .icon-close, .button_white, .sidenav_wrapper:hover i, .sidenav_wrapper-active i, .sidenav_wrapper:hover span, .closebtn i, .th-sort-can-label__active i.icon-success, .group-header, .sub-text a, .form_start .forgot-password-link{
    color:${({ theme }) => theme.mainColor} !important;
  }
  .tbody tr:hover, .tbody tr.tr_checked {
    background: ${({ theme }) => theme.light};
  }
  .button_white:hover{
    background:#fff;
  }
  .checkmark {
    border: 2px solid ${({ theme }) => theme.middle};
  }
  .pagination-numb_active{
    background-image:${({ theme }) => theme.pagination}
  }
  .button_light, .option {
    background-color: ${({ theme }) => theme.buttonLight};
    color: ${({ theme }) => theme.mainColor};
  }
  .button_light:hover {
    background-color: ${({ theme }) => theme.buttonLight};
  }
  .img-user{
    background: ${({ theme }) => theme.light};
  }
  .tooltip i, .img-user {
    color: ${({ theme }) => theme.middle};
  }
  .tbody-tr-grouped_show, .slick-current .plan {
    background: ${({ theme }) => theme.light};
  }
  .sidenav_list_wrapper {
    box-shadow: ${({ theme }) => theme.boxShadow}
  }
  .sidenav_wrapper:hover, .sidenav_wrapper-active  {
    background: ${({ theme }) => theme.buttonLight};
    box-shadow: inset 2px 0px 0px ${({ theme }) => theme.mainColor};
    color: ${({ theme }) => theme.mainColor}
  }
  .plan, .node, input:checked + .slider, .slide-body_right, .slide-body_left, .plans-page .plans-container, .group, .action-buttons-wrapper {
    border: 1px solid ${({ theme }) => theme.light};
  }
  .report {
    filter: drop-shadow(0px -4px 16px ${({ theme }) => theme.light})
  }
  .slick-dots .dot {
    background: ${({ theme }) => theme.middle};
  }
  .plans-page .plans-container_top .slick-slide .slide-name, .plans-container_top, .group-header {
    border-bottom: 1px solid ${({ theme }) => theme.light};
  }
  .popup-wrapper-header_sticky {
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.light};
  }
  .popup-bottom_sticky {
    box-shadow: inset 0px 1px 0px ${({ theme }) => theme.light}
  }
`;

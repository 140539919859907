import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";

import { settingsSchema } from "../../constants/schemas";
import {
  getAllSettings,
  RESET_TO_GLOBAL,
  update,
  updateGlobalToken
} from "../../services/settings-service";
import { Store } from "../../store";
import * as TYPES from "../../types/actionTypes";
import { FormContext } from "../form/formContext";
import { PlatformContext } from "../platform/platformContext";
import { SettingsContext } from "./settingsContext";
import settingsReducer from "./settingsReducer";

export const SettingsState = ({ children }) => {
  const [settings, dispatch] = useReducer(settingsReducer);
  const { validateFields, changeFormFields } = useContext(FormContext);
  const { currentPlatform } = useContext(PlatformContext);
  const { showNotification } = useContext(Store);
  const [, token] = useState(false);

  const copyToken = () => {
    token(true);
    showNotification({
      message: "The token has been copied",
      status: 203
    });
  };

  useEffect(() => {
    if (settings?.id) {
      changeFormFields(settings);
    }
  }, [settings]);

  const getSettings = useCallback(async () => {
    try {
      const { data } = await getAllSettings(currentPlatform);
      dispatch({
        type: TYPES.GET_SETTINGS,
        data
      });
    } catch (e) {
      console.log(e);
    }
  }, [currentPlatform]);

  const updateSettings = async () => {
    try {
      const updatedData = await validateFields(settingsSchema(settings.type));

      if (updatedData) {
        const { data } = await update(currentPlatform, updatedData);

        dispatch({
          type: TYPES.UPDATE_SETTINGS,
          data
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resetToGlobal = async (id) => {
    try {
      const { data } = await RESET_TO_GLOBAL(currentPlatform, id);
      if (data) {
        dispatch({
          type: TYPES.UPDATE_SETTINGS,
          data: data.settings
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateToken = async () => {
    try {
      const {
        status,
        data: { token }
      } = await updateGlobalToken(currentPlatform);

      dispatch({
        type: TYPES.UPDATE_TOKEN,
        token
      });
      showNotification({
        status,
        message: "New token generated successfully"
      });
    } catch (e) {
      console.log(e);
    }
  };

  const value = useMemo(
    () => ({
      updateSettings,
      getSettings,
      updateToken,
      copyToken,
      settings,
      setSettings: dispatch,
      resetToGlobal
    }),
    [settings, validateFields]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

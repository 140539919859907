import { useEffect } from "react";

export const useCloseOnAction = (event: string, func: any) => {
  useEffect(() => {
    document.addEventListener(event, func, false);

    return () => {
      document.removeEventListener(event, func, false);
    };
  }, [func, event]);
};

import axios from "axios";

import { API_URL } from "../constants/api";
import { ROLES } from "../constants/roles";

type User = {
  accessToken: string;
  refreshToken: string;
  firstName: string;
  lastName: string;
  role: number;
  isNew: boolean;
} | null;

class AuthService {
  public user: User;
  constructor() {
    this.updateStatus = this.updateStatus.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.logout = this.logout.bind(this);
    this.isRole = this.isRole.bind(this);
    this.user = this.userFromStorage;
  }

  public async updateStatus() {
    try {
      const { data } = await axios.put(`${API_URL}/users/status`);
      const { isNew }: { isNew: boolean } = data.user;
      this.currentUser(Object.assign(this.user, { isNew }));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async login(loginData: { email: string; password: string }) {
    try {
      const res = await axios.post(`${API_URL}/login`, loginData);
      if (res) {
        const {
          firstName,
          lastName,
          accessToken,
          role,
          isNew,
          refreshToken
        } = res.data;
        this.currentUser({
          accessToken,
          refreshToken,
          firstName,
          lastName,
          role,
          isNew
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async refreshToken() {
    try {
      const { data } = await axios.put(`${API_URL}/refresh`, null, {
        headers: {
          "refresh-token": this.user?.refreshToken
        }
      });
      const {
        firstName,
        lastName,
        accessToken,
        role,
        isNew,
        refreshToken
      } = data;
      this.currentUser({
        accessToken,
        refreshToken,
        firstName,
        lastName,
        role,
        isNew
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async forgotPassword(email: string, resend: boolean) {
    try {
      return await axios.post(
        `${API_URL}/forgot-password${resend ? `?resend=true` : ""}`,
        {
          link: window.location.origin,
          email
        }
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async logout() {
    try {
      await axios.post(`${API_URL}/logout`, null, {
        headers: {
          "refresh-token": this.user?.refreshToken
        }
      });

      localStorage.removeItem("user");
      window.location.href = "/";
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private currentUser(user: User | null): void {
    localStorage.setItem("user", JSON.stringify(user));
    this.user = user;
  }

  private get userFromStorage(): User | null {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public authHeader() {
    const user: string | null = localStorage.getItem("user");
    if (user) {
      return `Bearer ${JSON.parse(user).accessToken}`;
    }
  }

  public isRole(role: "Admin" | "Lead" | "Member"): boolean {
    if (this?.user && ROLES[this.user.role] === role) {
      return true;
    }
    return false;
  }
}

export default new AuthService();

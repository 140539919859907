import "./index.scss";

import { useCallback, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useRouteMatch } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { platformsRegExp } from "../../constants/platforms";
import { ROLES } from "../../constants/roles";
import { PlatformContext } from "../../context/platform/platformContext";
import { PopupsContext } from "../../context/popup/popupsContext";
import { useCloseOnAction } from "../../hooks/useCloseOnAction";
import AuthService from "../../services/auth-service";

export const Menu = ({
  mapAllowedRoutes
}: {
  mapAllowedRoutes: any;
}): JSX.Element => {
  const { user } = AuthService;

  const [open, setOpen] = useState(false);

  function openNav() {
    const menu: HTMLElement | null = document.getElementById("nav");

    menu ? (menu.style.width = "282px") : false;
    menu ? menu.classList.add("open") : false;

    setOpen(true);
  }

  const closeNav = useCallback((): void => {
    const menu: HTMLElement | null = document.getElementById("nav");

    menu ? (menu.style.width = "92px") : false;
    menu ? menu.classList.remove("open") : false;

    setOpen(false);
  }, []);

  const escFunction = useCallback(
    (event) => event.keyCode === 27 && closeNav(),
    [closeNav]
  );

  useCloseOnAction("keydown", escFunction);

  return (
    <div id="nav" className="sidenav">
      <span
        className="closebtn"
        onClick={({ currentTarget }) => {
          currentTarget.parentElement?.classList.contains("open")
            ? closeNav()
            : openNav();
        }}>
        <i className="icon-arrow-left" />
      </span>
      <div className="sidenav_list_wrapper">
        <div className="logo-wrapper">
          <a href="/" className="logo">
            <Logo />
          </a>
        </div>
        {mapAllowedRoutes.map(
          (route: any) =>
            route.display && (
              <div
                className={`sidenav_wrapper ${
                  useRouteMatch(route.path) ? "sidenav_wrapper-active" : ""
                }`}
                key={route.name}>
                <PlatformContext.Consumer>
                  {({ currentPlatform }) => (
                    <Link
                      to={route.path.replace(
                        platformsRegExp,
                        currentPlatform.toLowerCase()
                      )}
                      onClick={closeNav}>
                      {!open ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 100, hide: 100 }}
                          overlay={
                            <Tooltip id="button-tooltip">{route.name}</Tooltip>
                          }>
                          <i className={route.iconClass} />
                        </OverlayTrigger>
                      ) : (
                        <i className={route.iconClass} />
                      )}
                      <span>{route.name}</span>
                    </Link>
                  )}
                </PlatformContext.Consumer>
              </div>
            )
        )}
        <div className="sidenav_bottom">
          <div className="login-info">
            {!open ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 100 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    {user?.firstName}
                    <br />
                    {user?.lastName}
                    <br />
                    {user && ROLES[user?.role]}
                  </Tooltip>
                }>
                <span className="img-user">
                  <i className="icon-user" />
                </span>
              </OverlayTrigger>
            ) : (
              <span className="img-user">
                <i className="icon-user" />
              </span>
            )}

            <div className="info">
              <span className="name">{user?.firstName}</span>
              <span className="name">{user?.lastName}</span>
              <span className="role">{user && ROLES[user?.role]}</span>
            </div>
          </div>
          <a
            className="sidenav_wrapper"
            href="https://orchestrator.engenious.io/docs"
            target="_blank"
            rel="noreferrer">
            {!open ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 100 }}
                overlay={<Tooltip id="button-tooltip">Help</Tooltip>}>
                <i onClick={openNav} className="icon-help" />
              </OverlayTrigger>
            ) : (
              <i onClick={openNav} className="icon-help" />
            )}
            <span>Help</span>
          </a>
          <PopupsContext.Consumer>
            {({ toggleOpenPopup }) => (
              <div
                className="sidenav_wrapper"
                onClick={() => toggleOpenPopup({ logOut: true })}>
                {!open ? (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 100 }}
                    overlay={<Tooltip id="button-tooltip">Log Out</Tooltip>}>
                    <i className="icon-exit" />
                  </OverlayTrigger>
                ) : (
                  <i className="icon-exit" />
                )}
                <span className="exit">Log Out</span>
              </div>
            )}
          </PopupsContext.Consumer>
        </div>
      </div>
    </div>
  );
};

import axios from "axios";

import { API_URL } from "../constants/api";
import { Entity, User } from "../types/types";

export const GET_USERS = (entity: Entity = "users", params?: any) => {
  return axios.get(`${API_URL}/${entity}`, { params });
};

export const GET_INVITES = (params: any) => {
  return axios.get(`${API_URL}/invites`, { params });
};

export const GET_USER_BY_KEY = (key: string) =>
  axios.get(`${API_URL}/invite?code=${key}`);

export const CREATE_INVITE = (data: any) => {
  return axios.post(`${API_URL}/invites`, data);
};

export const DELETE_USER = (ids: Array<string>, entity: Entity) => {
  return axios.delete(`${API_URL}/${entity}`, {
    data: entity === "users" ? { users: ids } : { organizations: ids }
  });
};

export const DELETE_INVITES = (ids: Array<string>) => {
  return axios.delete(`${API_URL}/invites`, { data: { invites: ids } });
};

export const CREATE_ORGANIZATION = (data: any) => {
  return axios.post(`${API_URL}/organizations`, data);
};

export const UPDATE_ROLE = (id: number, role: number) => {
  return axios.put(`${API_URL}/users/${id}`, { role });
};

export const ACTIVATE_USER = (key: string, data: any) => {
  return axios.post(`${API_URL}/invite/?code=${key}`, data);
};

export const GET_RESET_PASSWORD = (token: string) =>
  axios.get(`${API_URL}/reset-password/${token}`);

export const RESET_PASSWORD = (key: string, data: any) => {
  return axios.post(`${API_URL}/reset-password/${key}`, data);
};

import {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react";

import { testInfoSchema } from "../../constants/schemas";
import { GET_TESTS, UPDATE_TEST } from "../../services/tests-service";
import * as TYPES from "../../types/actionTypes";
import { FormContext } from "../form/formContext";
import { ParamsContext } from "../params/paramsContext";
import { PlansContext } from "../plans/plansContext";
import { PlatformContext } from "../platform/platformContext";
import { PopupsContext } from "../popup/popupsContext";
import { TestsContext } from "./testsContext";
import tests from "./testsReducer";

export const TestsState = ({ children }) => {
  const [state, dispatch] = useReducer(tests);
  const { validateFields, changeFormFields } = useContext(FormContext);
  const { params } = useContext(ParamsContext);
  const { currentPlatform } = useContext(PlatformContext);
  const { toggleOpenPopup } = useContext(PopupsContext);
  const { plan } = useContext(PlansContext);
  const [openTestID, setOpenTest] = useState(0);
  const getTestInfo = useRef(null);

  useEffect(() => {
    if (!openTestID) return;

    if (plan?.items && openTestID) {
      getTestInfo.current = plan.items.find((test) => test.id === openTestID);
    } else if (state?.items) {
      getTestInfo.current = state.items.find((test) => test.id === openTestID);
    }

    changeFormFields(getTestInfo.current);
  }, [openTestID, state, plan]);

  const getTests = async () => {
    try {
      const statusArr = Object.keys(params.status)
        .filter((s) => params.status[s])
        .map((s) => `status=${s.toUpperCase()}`);

      const { data } = await GET_TESTS(currentPlatform, {
        ...params,
        status: statusArr
      });

      if (data) {
        dispatch({
          type: TYPES.GET_TESTS,
          data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateTestInfo = async () => {
    try {
      const data = await validateFields(testInfoSchema);

      if (data) {
        const {
          data: { test }
        } = await UPDATE_TEST({
          ...data,
          jiraLink: data.jiraLink || null,
          testRailLink: data.testRailLink || null
        });

        if (test) {
          dispatch({
            type: TYPES.UPDATE_TEST,
            test
          });
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const openSettings = (testId) => {
    setOpenTest(testId);
    toggleOpenPopup({ testInfo: true });
  };

  const value = useMemo(
    () => ({
      updateTestInfo,
      getTests,
      openSettings,
      tests: state?.items,
      meta: state?.meta,
      getTestInfo: getTestInfo.current
    }),
    [state, plan, getTestInfo.current, currentPlatform, params, validateFields]
  );

  return (
    <TestsContext.Provider value={value}>{children}</TestsContext.Provider>
  );
};

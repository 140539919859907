import axios from "axios";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { API_URL } from "../constants/api";
import AuthService from "../services/auth-service";
import { getType } from "../utils/convertString";

const Store = createContext();
const { Provider } = Store;

const GlobalState = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [notification, toggleNotification] = useState({
    show: false
  });
  const { authHeader, refreshToken } = AuthService;

  const showNotification = (data) => {
    toggleNotification({
      show: true,
      type: getType(data.status),
      ...data
    });
  };

  const goToLogin = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const closeNotification = () => toggleNotification({ show: false });

  useEffect(() => {
    let timer = notification.show && setTimeout(closeNotification, 6000);
    return () => clearTimeout(timer);
  }, [notification.show]);

  useMemo(() => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.baseUrl = API_URL;

    let call = {};
    axios.interceptors.request.use(
      function (config) {
        config.headers.common["Authorization"] = authHeader();

        if (config.method === "get") {
          setLoader(true);
        }

        //cancel duplicate GET requests
        if (
          config.method === "get" &&
          config.method === call.method &&
          JSON.stringify(config.params) === JSON.stringify(call.params) &&
          config.url === call.url
        ) {
          setLoader(false);
          return {
            ...config,
            cancelToken: new axios.CancelToken((cancel) =>
              cancel("Cancel repeated request")
            )
          };
        } else {
          call = {
            url: config.url,
            method: config.method,
            params: config.params
          };
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const { data, status } = response;

        setLoader(false);

        if (
          typeof data?.message === "string" ||
          (typeof data === "string" && data.length)
        ) {
          showNotification({ status, message: data?.message || data });
        }

        return response;
      },
      function (error) {
        if (!error.response) return false;
        const { data, status, config } = error.response;
        const originalRequest = error.config;

        switch (status) {
          case 401:
            if (data.message === "jwt expired" && !originalRequest._retry) {
              originalRequest._retry = true;
              return new Promise(function (resolve, reject) {
                refreshToken()
                  .then(() => {
                    originalRequest.headers.Authorization = authHeader();
                    resolve(axios(originalRequest)); // send the previous request with a new access token
                  })
                  .catch(() => {
                    reject(goToLogin()); //404 logout
                  });
              });
            }
            if (config.url.includes("login")) {
              showNotification({ status, ...data });
            }
            break;
          case 404:
            if (config.url.includes("refresh")) {
              goToLogin();
            } else {
              window.location.href = "/404";
            }
            break;
          default:
            showNotification(
              data
                ? {
                    status,
                    message: data?.message || data
                  }
                : {
                    message: "Internal Server Error",
                    status: 500
                  }
            );
        }
        return Promise.reject(error);
      }
    );
  }, [authHeader]);

  const value = useMemo(
    () => ({ loader, notification, showNotification, closeNotification }),
    [loader, notification.show]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { GlobalState, Store };

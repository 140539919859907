import "./assets/scss/global.scss";

import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";

import * as themes from "./assets/theme";
import { GlobalStyles } from "./assets/theme/global";
import { Menu } from "./components/Menu";
import { Notification } from "./components/Notification/Notification";
import { Popup } from "./components/Popup/Popup";
import { SomeText } from "./components/PopupBody/SomeText";
import allowedRoutes from "./config/privateRoutesConfig";
import { GroupsState } from "./context/groups/groupsState";
import { InvitesState } from "./context/invites/invitesState";
import { NodeState } from "./context/node/nodeState";
import { ParamsState } from "./context/params/paramsState";
import { PlansState } from "./context/plans/plansState";
import { PlatformContext } from "./context/platform/platformContext";
import { PlatformState } from "./context/platform/platformState";
import { PopupsContext } from "./context/popup/popupsContext";
import { SettingsState } from "./context/settings/settingsState";
import { TableState } from "./context/table/tableState";
import { TestsState } from "./context/tests/testsState";
import { UsersState } from "./context/users/usersState";
import Router from "./router";
import AuthService from "./services/auth-service";
import { getAllowedRoutes } from "./utils/getAllowedRoutes";

export const App = () => {
  const { popups, toggleOpenPopup, getHintClass } = useContext(PopupsContext);
  const { user, logout } = AuthService;
  const mapAllowedRoutes = getAllowedRoutes(allowedRoutes);

  const logOut = () => {
    toggleOpenPopup({ logOut: false });
    logout();
  };

  return (
    <PlatformState>
      <PlatformContext.Consumer>
        {({ currentPlatform }) => (
          <ThemeProvider theme={themes[currentPlatform]}>
            <GlobalStyles />
            {user && (
              <>
                <Menu mapAllowedRoutes={mapAllowedRoutes} />
                {popups.logOut && (
                  <Popup
                    name="logOut"
                    header="Log Out"
                    body={<SomeText text="Are you sure you want to Log Out?" />}
                    button={{
                      size: "big",
                      color: "red",
                      text: "Log Out",
                      onClick: logOut,
                      disabled: false
                    }}
                  />
                )}
              </>
            )}
            <Notification />
            <main className={`${user ? "in" : "out"} ${getHintClass()}`}>
              <ParamsState>
                <UsersState>
                  <InvitesState>
                    <GroupsState>
                      <SettingsState>
                        <PlansState>
                          <TestsState>
                            <TableState>
                              <NodeState>
                                <Router mapAllowedRoutes={mapAllowedRoutes} />
                              </NodeState>
                            </TableState>
                          </TestsState>
                        </PlansState>
                      </SettingsState>
                    </GroupsState>
                  </InvitesState>
                </UsersState>
              </ParamsState>
            </main>
          </ThemeProvider>
        )}
      </PlatformContext.Consumer>
    </PlatformState>
  );
};

import * as yup from "yup";

export const node = {
  type: yup.string().trim().required("Can'not read type of authorization"),
  username: yup.string().trim().max(127).nullable().required("Required field"),
  password: yup.string().max(127).required("Required field"),
  privateKey: yup
    .string()
    .nullable()
    .max(4095)
    .strict(false)
    .trim()
    .required("Required field"),
  publicKey: yup
    .string()
    .nullable()
    .max(4095)
    .strict(false)
    .trim()
    .required("Required field"),
  passphrase: yup.string().nullable().max(127).required("Required field")
};

import { ObjectLike } from "../types/types";

export const getKeyByValue = (obj: ObjectLike, value: number | string) =>
  Object.keys(obj).find((key) => obj[key] === value);

export const removeProperties = (
  obj: { [key: string]: any },
  propertiesArray: Array<string | number>
) => {
  const newObj = { ...obj };
  for (const prop of propertiesArray) {
    delete newObj[prop];
  }

  return newObj;
};

export const parseInputName = (name: string) => {
  const fullPath = name.split(".");

  return {
    parentName: fullPath[0],
    thisName: fullPath[1],
    thisInputName: fullPath[2]
  };
};

export const setPropertiesToNull = (
  obj: { [key: string]: any },
  propertiesArray: Array<string | number>
): { [key: string]: any } => {
  const newObj = { ...obj };

  for (const prop of propertiesArray) {
    newObj[prop.toString().trim()] = null;
  }

  return newObj;
};

import axios from "axios";

import { API_URL } from "../constants/api";
import { Platform, Test } from "../types/types";
import { generateSearchString } from "../utils/convertString";

export const GET_TESTS = (platform: Platform = "IOS", params?: any) => {
  const paramsWithStatus =
    params && params.status && generateSearchString(params.status);
  params && delete params.status;
  return axios.get(
    `${API_URL}/tests/platform=${platform}${
      paramsWithStatus ? `?${paramsWithStatus}` : ""
    }`,
    {
      params
    }
  );
};

export const UPDATE_TEST = (test: Test) => {
  return axios.put(`${API_URL}/tests/${test.id}`, test);
};

export const DELETE_TESTS = (id: number, tests: Array<number>) =>
  axios.delete(`${API_URL}/test-plans/${id}/tests`, {
    data: { tests }
  });

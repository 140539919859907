import {
  DELETE_TESTS_FROM_PLAN,
  GET_PLAN,
  GET_PLAN_CHART,
  GET_PLANS,
  UPDATE_PLAN,
  UPDATE_TEST_IN_PLAN
} from "../../types/actionTypes";
import { Test, TestPlan, TestPlanInfo } from "../../types/types";

const initialState = {};

export default function plans(state: any = initialState, action: any): void {
  switch (action.type) {
    case GET_PLANS:
      return action.data;
    case GET_PLAN_CHART:
      return {
        ...state,
        testPlans: state.testPlans.map((testPlan: TestPlanInfo) => {
          if (testPlan.id === action.data.id) {
            return {
              ...testPlan,
              builds: action.data.builds
            };
          }
          return testPlan;
        })
      };
    case GET_PLAN:
      return action.plan;
    case DELETE_TESTS_FROM_PLAN:
      return {
        ...state,
        items: state.items.filter(
          (test: Test) =>
            !action.ids.some((id: string) => Number(id) === test.id)
        )
      };
    case UPDATE_PLAN:
      return {
        ...state,
        items: state.items.map((plan: TestPlan) => {
          if (plan.id === action.plan.id) {
            return action.plan;
          }

          return plan;
        })
      };
    case UPDATE_TEST_IN_PLAN:
      return {
        ...state,
        items: state.items.map((test: Test) => {
          if (test.id === action.test.id) {
            return action.test;
          }

          return test;
        })
      };
    default:
      return state;
  }
}

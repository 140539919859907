import axios from "axios";

import { API_URL } from "../constants/api";
import { Platform } from "../types/types";

export const getAllSettings = (platform: Platform = "IOS") => {
  return axios.get(`${API_URL}/settings/platform=${platform}`);
};

export const update = (platform: Platform = "IOS", data: any) => {
  return axios.put(`${API_URL}/settings/platform=${platform}`, data);
};

export const updateGlobalToken = (platform: Platform = "IOS") => {
  return axios.put(`${API_URL}/settings/platform=${platform}/generate`, {
    params: { platform }
  });
};

export const RESET_TO_GLOBAL = (platform: Platform = "IOS", id: number) =>
  axios.post(
    `${API_URL}/test-plans/platform=${platform}/${id}/reset-to-default`
  );

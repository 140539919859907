import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState
} from "react";
import { useHistory } from "react-router-dom";

import { nodeSchema } from "../../constants/schemas";
import {
  CREATE_NODE,
  DELETE_NODE,
  GET_NODE,
  GET_NODES,
  UPDATE_NODE
} from "../../services/node-service";
import * as TYPES from "../../types/actionTypes";
import { removeProperties } from "../../utils/objectHelper";
import { FormContext } from "../form/formContext";
import { PlatformContext } from "../platform/platformContext";
import { NodeContext } from "./nodeContext";
import nodesReducer from "./nodeReducer";

export const NodeState = ({ children }) => {
  const {
    form,
    changeFormFields,
    validateFields,
    setErrors,
    errors
  } = useContext(FormContext);
  const { currentPlatform } = useContext(PlatformContext);
  const platformType =
    currentPlatform === "ios" ? 0 : currentPlatform === "android" ? 1 : 4;
  const [nodes, setNodes] = useReducer(nodesReducer);
  const [node, setNode] = useState({});
  const history = useHistory();

  const addUDIDInput = useCallback(
    async (type) => {
      let isPreviousValid = true;

      try {
        await nodeSchema(platformType)
          .validate(form, { abortEarly: false })
          .catch((currentErrors) => {
            isPreviousValid = !currentErrors.inner.find((err) =>
              err.path.includes(`${type}[`)
            );

            const newErrorsObj = Object.assign(
              ...currentErrors.inner.map(({ path, message }) => ({
                [path]: message
              }))
            );

            setErrors(newErrorsObj);
          });

        isPreviousValid &&
          changeFormFields({
            ...form,
            UDID: {
              ...form.UDID,
              [type]: form.UDID[type] ? [...form.UDID[type], ""] : [""]
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    [platformType, form]
  );

  const changeUDIDValue = useCallback(
    (e, index, type) => {
      const value = e.target.value.trim();
      const newArr = [...form.UDID[type]];
      let message = null;

      newArr[index] = value;

      if (value.length < 3) {
        message = "Must be more than or equal 3 characters";
      } else if (value.length > 100) {
        message = "Must be less than or equal 100 characters";
      }

      if (!message) {
        setErrors((errors) => ({
          ...errors,
          [`UDID.${type}[${index}]`]: message
        }));
      } else {
        setErrors(
          removeProperties(errors, ["UDID.simulators", "UDID.devices"])
        );
      }

      changeFormFields((form) => ({
        ...form,
        UDID: {
          ...form.UDID,
          [type]: newArr
        }
      }));
    },
    [form]
  );

  const removeInput = useCallback(
    (index, type) => {
      const cleredArray = form.UDID[type].filter((_, i) => i !== index);
      let updatedUDID = {};

      const clearErrorForInput = removeProperties(errors, [
        `UDID.${type}[${index}]`
      ]);

      setErrors(clearErrorForInput);

      if (cleredArray.length) {
        updatedUDID = {
          ...form.UDID,
          [type]: cleredArray
        };
      } else {
        updatedUDID = removeProperties(form.UDID, [type]);
      }

      changeFormFields({
        ...form,
        UDID: updatedUDID
      });
    },
    [form]
  );

  const getNode = useCallback(async (id) => {
    try {
      const { data } = await GET_NODE(id);

      setNode(data);
      changeFormFields(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getNodes = useCallback(async (currentPlatform) => {
    try {
      const { data } = await GET_NODES(currentPlatform);

      if (data) {
        setNodes({
          type: TYPES.GET_NODES,
          data
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const updateNode = useCallback(async () => {
    try {
      const data = await validateFields(nodeSchema(platformType));
      if (data) {
        const {
          data: { node }
        } = await UPDATE_NODE(data.id, data);

        setNode(node);
        changeFormFields(node);
      }
    } catch (e) {
      console.log(e);
    }
  }, [validateFields]);

  const createNode = useCallback(async () => {
    try {
      const node = await validateFields(nodeSchema(platformType));
      if (node) {
        await CREATE_NODE(currentPlatform, { node: node });
        history.push(`/${currentPlatform}/nodes`);
      }
    } catch (e) {
      console.log(e);
    }
  }, [validateFields, platformType]);

  const removeNode = useCallback(async (id) => {
    try {
      await DELETE_NODE(id);

      setNodes({
        type: TYPES.REMOVE_NODE,
        id
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const value = useMemo(
    () => ({
      addUDIDInput,
      changeUDIDValue,
      removeInput,
      updateNode,
      createNode,
      removeNode,
      getNode,
      getNodes,
      nodes,
      node
    }),
    [node, nodes, validateFields]
  );

  return <NodeContext.Provider value={value}>{children}</NodeContext.Provider>;
};

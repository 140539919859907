import { GET_USERS, UPDATE_USER } from "../../types/actionTypes";
import { User } from "../../types/types";

const initialState = {};

export default function users(state: any = initialState, action: any) {
  switch (action.type) {
    case GET_USERS:
      return action.data;
    // case DELETE_USER:
    //   return {
    //     ...state,
    //     items: state.items.filter(
    //       (user: User) =>
    //         !action.ids.some((id: string) => Number(id) === user.id)
    //     ),
    //   };
    case UPDATE_USER:
      return {
        ...state,
        items: state.items.map((user: User) => {
          if (user.id === action.data.id) {
            return { ...user, role: action.data.role };
          }

          return user;
        })
      };
    default:
      return state;
  }
}

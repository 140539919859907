import { GET_INVITES } from "../../types/actionTypes";

const initialState = {};

export default function invites(state: any = initialState, action: any) {
  switch (action.type) {
    case GET_INVITES:
      return action.data;
    // case DELETE_INVITES:
    //   return {
    //     ...state,
    //     items: state.items.filter(
    //       (invite: User) =>
    //         !action.ids.some((id: string) => Number(id) === invite.id)
    //     ),
    //   };
    default:
      return state;
  }
}

import "./Notification.scss";

import React from "react";

import { Store } from "../../store";

export const Notification = (): JSX.Element => (
  <Store.Consumer>
    {({ closeNotification, notification: { message, status, type } }) => {
      return (
        status && (
          <div className={`notification notification_${type}`}>
            <div className="message-text-wr">
              <span className="notification-icon">
                <i className={`icon-${type}`} />
              </span>
              <span className="message-text uppercase">{message}</span>
            </div>
            <span className="message-close" onClick={closeNotification}>
              <i className="icon-close" />
            </span>
          </div>
        )
      );
    }}
  </Store.Consumer>
);

import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { platforms } from "../../constants/platforms";
import { useLocationHook } from "../../hooks/useLocation";
import { PlatformContext } from "./platformContext";

export const PlatformState = ({ children }) => {
  const [currentPlatform, setPlatform] = useState(
    localStorage.getItem("platform") || "ios"
  );
  const history = useHistory();
  const { currentUrl } = useLocationHook();

  useMemo(() => {
    if (currentUrl) {
      const currentPath = currentUrl.replace("/", "").split("/")[0];
      if (platforms.some((p) => p.name.toLowerCase() === currentPath)) {
        localStorage.setItem("platform", currentPath.toLowerCase());
        setPlatform(currentPath.toLowerCase());
      }
    }
  }, [currentUrl]);

  const changeCurrentPlatform = (platform) =>
    history.push(currentUrl.replace(currentPlatform, platform));

  const value = useMemo(
    () => ({
      currentPlatform,
      changeCurrentPlatform
    }),
    [currentPlatform, currentUrl]
  );

  return (
    <PlatformContext.Provider value={value}>
      {children}
    </PlatformContext.Provider>
  );
};

import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { Preloader } from "./components/Preloader/Preloader";
import * as routes from "./constants/routes";
import AuthService from "./services/auth-service";
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Invite = lazy(() => import("./pages/Invite"));
const HomeRedirect = lazy(() => import("./pages/HomeRedirect"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Page404 = lazy(() => import("./pages/Page404"));
const SignIn = lazy(() => import("./pages/SignIn"));

const Router = ({ mapAllowedRoutes }) => {
  const { user } = AuthService;
  const { HOME, FORGOT_PASSWORD, RESET_PASSWORD, INVITE } = routes;

  return (
    <>
      {user ? (
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route exact path={HOME} component={HomeRedirect} />
            {mapAllowedRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <Route path="*" component={Page404} />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route path={FORGOT_PASSWORD} component={ForgotPassword} />
            <Route
              exact
              path={`${RESET_PASSWORD}/:key`}
              component={ResetPassword}
            />
            <Route exact path={`${INVITE}/:key`} component={Invite} />
            <Route path="*" component={SignIn} />
          </Switch>
        </Suspense>
      )}
    </>
  );
};

const isEqual = (prevProps, nextProps) =>
  prevProps.mapAllowedRoutes === nextProps.mapAllowedRoutes;

export default React.memo(Router, isEqual);

import React, { useMemo, useState } from "react";

import {
  parseInputName,
  removeProperties,
  setPropertiesToNull
} from "../../utils/objectHelper";
import { FormContext } from "./formContext";

export const FormState = ({ children }) => {
  const [form, changeFormFields] = useState({});
  const [errors, setErrors] = useState({});

  const setNodeAuthFormFields = (fields) => {
    return {
      ...form,
      authorization: {
        ...form.authorization,
        data: setPropertiesToNull(form.authorization.data, fields)
      }
    };
  };

  const nodeForm = () => {
    if (form.authorization) {
      switch (form.authorization.type) {
        case "0":
          return setNodeAuthFormFields(["password", "publicKey", "passphrase"]);

        case "1":
          return setNodeAuthFormFields(["password"]);

        case "2":
          return setNodeAuthFormFields([
            "publicKey",
            "privateKey",
            "passphrase"
          ]);

        case "3":
          return setNodeAuthFormFields([
            "password",
            "publicKey",
            "privateKey",
            "passphrase"
          ]);

        default:
          return form;
      }
    }
    return false;
  };

  const validateFields = (schema) => {
    return schema
      .validate(nodeForm() ? nodeForm() : form, { abortEarly: false })
      .catch((errors) => {
        const newErrorsObj = Object.assign(
          ...errors.inner.map(({ path, message }) => ({
            [path]: message
          }))
        );
        setErrors(newErrorsObj);
        return false;
      });
  };

  const onChangeInput = ({ name, value, type }, trimValue) => {
    let updatedState = {};
    value = trimValue
      ? value.trim()
      : type === "number" && value
      ? +value
      : value;

    if (name.includes(".")) {
      const { parentName, thisName, thisInputName } = parseInputName(name);
      if (!form[parentName]) return;
      if (thisInputName) {
        updatedState = {
          ...form,
          [parentName]: {
            ...form[parentName],
            [thisName]: {
              ...form[parentName][thisName],
              [thisInputName]: value
            }
          }
        };
      } else {
        updatedState = {
          ...form,
          [parentName]: {
            ...form[parentName],
            [thisName]: value
          }
        };
      }
    } else {
      updatedState = {
        ...form,
        [name]: value
      };
    }

    changeFormFields(updatedState);

    if (errors[name]) {
      setErrors(removeProperties(errors, [name]));
    }
  };

  const getValue = (name) => {
    if (name.includes(".")) {
      const { parentName, thisName, thisInputName } = parseInputName(name);
      return form &&
        thisInputName &&
        form[parentName] &&
        form[parentName][thisName]
        ? form[parentName][thisName][thisInputName]
        : form[parentName]
        ? form[parentName][thisName]
        : "";
    }

    return form && form[name] !== undefined && form[name] !== null
      ? form[name]
      : "";
  };

  const value = useMemo(
    () => ({
      changeFormFields,
      form,
      validateFields,
      errors,
      setErrors,
      onChangeInput,
      getValue,
      clearFields: () => changeFormFields({})
    }),
    [form, errors]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

import "./Button.scss";

import React from "react";

type buttonProps = {
  colorBtn?:
    | "button_red"
    | "button_white"
    | "button_silver"
    | "button_light"
    | "button_redLight";
  size?: "button_middle" | "button_big";
  disabled?: boolean;
  isBig?: boolean;
  text: string;
  onClick: () => void;
  icon?: string;
};

export const Button = (props: buttonProps): JSX.Element => (
  <button
    disabled={props.disabled}
    onClick={(e) => {
      e.preventDefault();
      props.onClick();
    }}
    className={`button ${props.colorBtn ? props.colorBtn : ""} ${
      props.size ? props.size : ""
    }`}>
    {props.icon && <i className={props.icon} />}
    {props.text}
  </button>
);

import React, { useCallback, useContext, useMemo, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { ADMIN, SUPER_ADMIN } from "../../constants/roles";
import {
  createOrganizationSchema,
  createUserSchema,
  resetPasswordSchema
} from "../../constants/schemas";
import AuthService from "../../services/auth-service";
import {
  CREATE_INVITE,
  CREATE_ORGANIZATION,
  DELETE_USER,
  GET_RESET_PASSWORD,
  GET_USERS,
  RESET_PASSWORD,
  UPDATE_ROLE
} from "../../services/users-service";
import * as TYPES from "../../types/actionTypes";
import { FormContext } from "../form/formContext";
import { ParamsContext } from "../params/paramsContext";
import { PopupsContext } from "../popup/popupsContext";
import { UsersContext } from "./usersContext";
import users from "./usersReducer";

export const UsersState = ({ children }) => {
  const [state, dispatch] = useReducer(users);
  const { toggleOpenPopup } = useContext(PopupsContext);
  const { validateFields, changeFormFields } = useContext(FormContext);
  const { params } = useContext(ParamsContext);
  const { user } = AuthService;
  const history = useHistory();

  const getUsers = useCallback(async () => {
    try {
      const { data } = await GET_USERS(params.entity, {
        ...params,
        status: null,
        search: params.name,
        name: null
      });
      if (data) {
        dispatch({
          type: TYPES.GET_USERS,
          data
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [params]);

  const createInvite = async () => {
    try {
      const data = await validateFields(createUserSchema);

      if (data) {
        await CREATE_INVITE({
          ...data,
          role: Number(data.role),
          link: window.location.origin
        });
        toggleOpenPopup({ newUser: false });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getUserByKey = useCallback(
    async (key) => {
      try {
        const { data } = await GET_RESET_PASSWORD(key);
        changeFormFields(data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [changeFormFields]
  );

  const resetPassword = async (key) => {
    try {
      const data = await validateFields(resetPasswordSchema);
      if (data) {
        await RESET_PASSWORD(key, data);
        history.push("/");
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateRole = async (id, role) => {
    try {
      const { status } = await UPDATE_ROLE(id, role);

      if (status === 200) {
        dispatch({
          type: TYPES.UPDATE_USER,
          data: {
            id,
            role
          }
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const deleteUser = async (ids) => {
    try {
      await DELETE_USER(ids, params.entity);
      getUsers();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const createOrganization = async () => {
    try {
      const data = await validateFields(createOrganizationSchema);

      if (data) {
        await CREATE_ORGANIZATION({
          ...data,
          link: window.location.origin
        });
        toggleOpenPopup({ newOrganization: false });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const canSelect = (role) => {
    if (
      (user?.role === SUPER_ADMIN && role === ADMIN) ||
      (user?.role === ADMIN && role !== ADMIN)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const value = useMemo(
    () => ({
      users: state?.items,
      meta: state?.meta,
      getUsers,
      createInvite,
      createOrganization,
      updateRole,
      deleteUser,
      canSelect,
      getUserByKey,
      resetPassword
    }),
    [state, params, validateFields]
  );

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

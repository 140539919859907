import blueIcon from "../img/pagination__active.svg";
import greenIcon from "../img/pagination-green__active.svg";
import orangeIcon from "../img/pagination-orange__active.svg";

export const ios = {
  body: "#fafcff",
  mainColor: "#056ae7",
  buttonHover: "#0560d2",
  pagination: `url(${blueIcon})`,
  buttonLight: "#FAFCFF",
  svgColor: "#5097EE",
  light: "#E6F0FD",
  middle: "#9BC3F5",
  boxShadow: "4px 0px 16px rgba(0, 114, 255, 0.06)"
};

export const android = {
  body: "#fcfcfc",
  mainColor: "#4BB462",
  buttonHover: "#5cd076",
  pagination: `url(${greenIcon})`,
  middle: "#B7E1C0",
  buttonLight: "#F6FBF7",
  light: "#EDF7EF",
  svgColor: "#81CB91",
  boxShadow: "4px 0px 16px #edf7ef"
};

export const windows = {
  body: "#FDF9F2",
  mainColor: "#DF8600",
  buttonHover: "#f9c45c",
  pagination: `url(${orangeIcon})`,
  middle: "#f0b849",
  buttonLight: "#fffaf0",
  light: "#FDF9F2",
  svgColor: "#f0b849",
  boxShadow: "4px 0px 16px #fffaf0"
};
